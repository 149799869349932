<template>
    <section id="technologies" class="py-40 h-full bg-gradient-to-t from-primary-content/100 to-primary-content/0">
        <div class="flex flex-col items-center mx-6 mb-14 md:mx-14 lg:mb-[72px] xl:mb-24 2xl:mb-32 lg:mx-32 xl:mx-72">
            <span
                class="text-sm font-semibold bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text mb-8 uppercase">
                {{ $t('Not just a team but an ecosystem') }}
            </span>
            <div class="flex flex-col gap-6 text-center 2xl:mx-40">
                <span class="text-2xl lg:text-[32px] 2xl:text-[44px] leading-9 lg:leading-10 2xl:leading-[56px]">
                    {{ $t('We identify top-tier tech talent to expand your team strategically') }}
                </span>

                <span class="text-sm uppercase">
                    {{ $t('you name it, we have it') }}
                </span>
            </div>
        </div>
        <div class="flex flex-col justify-center gap-7 xl:flex-row lg:gap-24 mt-24 mx-auto xl:w-4/5 px-2">
            <div class="flex flex-col w-full xl:w-[60%]">
                <Disclosure
                    v-for="(domain, index) in domains"
                    :key="domain.title"
                    v-slot="{open}"
                    :default-open="index === 0"
                    as="section"
                    class="mt-6 border-b border-base-200 pb-6">
                    <DisclosureButton
                        class="flex w-full justify-between rounded-lg px-4 py-2 text-left text-xl lg:text-[28px] font-medium"
                        :class="{
                            'text-primary': open,
                        }">
                        <div class="flex gap-7">
                            <component :is="domain.icon" class="size-8" /> <span>{{ domain.title }}</span>
                        </div>

                        <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="h-5 w-5" />
                    </DisclosureButton>
                    <DisclosurePanel class="flex flex-col gap-6 px-4 pb-2 pt-4 text-sm">
                        <span>
                            {{ domain.description }}
                        </span>
                        <div class="flex flex-col gap-4">
                            <span class="text-xl">
                                {{ domain.skillsHeader }}
                            </span>

                            <div class="flex flex-wrap gap-2">
                                <span
                                    v-for="skill in domain.skills"
                                    :key="skill"
                                    class="py-1 px-4 rounded-full border border-base-200">
                                    {{ skill }}
                                </span>
                            </div>
                        </div>
                    </DisclosurePanel>
                </Disclosure>
            </div>
            <div class="rounded-2xl relative w-full xl:w-[40%] h-[638px]">
                <video
                    class="absolute h-full w-full object-cover object-top rounded-2xl"
                    autoplay
                    muted
                    loop
                    disablePictureInPicture
                    playsinline>
                    <source src="/videos/top_tier.mp4" type="video/mp4" />
                </video>
                <div class="absolute inset-0 bg-[#0E1924] opacity-75 h-full rounded-2xl" />
                <div
                    class="absolute top-0 bg-base-content h-[40%] text-white text-2xl lg:text-3xl px-14 py-12 rounded-2xl">
                    {{
                        $t(
                            'Our powerful yet easy-to-use system frees up your time to focus on what truly matters: business success.',
                        )
                    }}
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import {PropType} from 'vue';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {ChevronUpIcon} from '@heroicons/vue/20/solid';
import {CodeBracketIcon, RectangleGroupIcon, UserCircleIcon} from '@heroicons/vue/24/outline';

type Step = {
    position: string;
    description: string;
};

defineProps({
    processName: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    steps: {
        type: Array as PropType<Step[]>,
        required: true,
    },
});

const domains = [
    {
        icon: CodeBracketIcon,
        title: 'Developers & AI Engineers',
        description: 'Sophisticated full-stack development using cutting-edge tools and frameworks.',
        skillsHeader: 'Development techs',
        skills: [
            'Java',
            'Kotlin',
            'PHP',
            'C/C++',
            'Golang',
            'Kotlin',
            'Android',
            'IOS',
            'Angular',
            'React',
            'Django',
            'GANTT Chart',
            'PERT Chart',
            'Unit Testing',
            'UML Diagrams',
            'Algorithm Analysis',
            'Profiling',
            'Agile',
            '...',
        ],
    },
    {
        icon: RectangleGroupIcon,
        title: 'Designers & User Experience (UX)',
        description:
            'Design solutions tailored to overcome business challenges with attention to user experience and customer journey from discovery, acquisition to conversion and retention.',
        skillsHeader: 'Design tools/skills',
        skills: [
            'Figma',
            'Miro',
            'Sketch',
            'Photoshop',
            'Adobe XD',
            'InVision',
            'Justinmind',
            'Adobe After Effects',
            'Adobe illustrator',
            'UXcam',
            'UXpin',
            'Marvel',
            'User-personas',
            'Customer journeys',
            'Competitive audit',
            'Design thinking',
            'A/B testing',
            'Design systems',
            '...',
        ],
    },
    {
        icon: CodeBracketIcon,
        title: 'Project managers',
        description:
            'Project managers who strategically guide teams to overcome business challenges by optimizing user experiences and driving customer journeys from initial discovery to long-term loyalty.',
        skillsHeader: 'Project managers tools/skills',
        skills: [
            'Cleos',
            'Asana',
            'Clickup',
            'basecamp',
            'Jira system',
            'Trello',
            'Wrike',
            'Monday',
            'Teamwork',
            'SWOT',
            'Decision Matrix',
            'SCRUM master',
            'SOAR',
            "Porter's Five Forces",
            '...',
        ],
    },
    {
        icon: UserCircleIcon,
        title: 'Human resources & Operations',
        description:
            'Manage and optimize processes, drive employee engagement, recruitment, talent management, and ensure coordination across departments to align HR and operations with business goals.',
        skillsHeader: 'Human resources & Operations tools/skills',
        skills: [
            'Workday',
            'BambooHR',
            'SAP SuccessFactor',
            'Greenhouse',
            'Zenefits',
            'Payroll Management',
            'Benefits Administration',
            'Employee Engagement',
            'Performance Reviews',
            'Talent Acquisition',
            'HR Analytics',
            'Risk Management',
            'Process Automation',
            'Strategic Planning',
            'Operational Efficiency',
            '...',
        ],
    },
];
</script>
